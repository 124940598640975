<template>
  <div>
    <div class="page-container mb-2">
      <h3
        class="header"
      >
        Bộ lọc
      </h3>
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Trạng thái</label>
            <v-select
              :reduce="label => label.id"
              label="name"
              :options="dataStatus || []"
              :placeholder="'Trạng thái'"
              @input="filterStatus"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Loại hình doanh nghiệp</label>
            <v-select
              v-model="urlQuery.bussinessTypeId"
              :reduce="label => label.id"
              label="name"
              :options="dataTypeBussiness || []"
              :placeholder="'Loại hình doanh nghiệp'"
              @input="filterTypeBussiness"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Tỉnh/Thành phố</label>
            <v-select
              v-model="urlQuery.provinceId"
              :reduce="label => label.id"
              label="name"
              :options="dataProvinces || []"
              :placeholder="'Tỉnh/Thành phố'"
              @input="filterProvinces"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Huyện/Quận</label>
            <v-select
              v-model="urlQuery.districtId"
              :reduce="label => label.id"
              label="name"
              :options="dataDistricts || []"
              :placeholder="'Huyện/Quận'"
              @input="filterDistricts"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Xã/Phường</label>
            <v-select
              v-model="urlQuery.wardId"
              :reduce="label => label.id"
              label="name"
              :options="dataWards || []"
              :placeholder="'Xã/Phường'"
              @input="filterWards"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm doanh nghiệp'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideButton="!(this.$infoPortal().province === 'Đắk Nông')"
        :iconButton="'RefreshCcwIcon'"
        :contentButton="syncStatus ? 'Đang đồng bộ dữ liệu...' :'Đồng bộ doanh nghiệp'"
        :tooltipBtn="'Đồng bộ dữ liệu danh sách các doanh nghiệp trên trục LGSP'"
        :disabledBtn="syncStatus"
        :hideDelete="$userData().roleIdentity !== 'EducationDepartment'"
        :hideAdd="$userData().roleIdentity !== 'EducationDepartment'"
        :hideExportFile="$userData().roleIdentity !== 'EducationDepartment'"
        :hideImportFile="$userData().roleIdentity !== 'EducationDepartment'"
        @clickDelete="deleteItems"
        @clickDowloadSample="dowloadFile"
        @clickExportExcel="downloadExcelAll"
        @importFile="importFileExcel($event)"
        @clickAdd="$router.push({name: 'business-add'})"
        @clickButton="onSyncBusiness"
        @search="search($event)"
      />
      <!--Phần bảng-->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataRows || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã doanh nghiệp -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ $t(props.row.code) }}</span>
            </b-badge>
          </span>

          <!-- Column:Tên doanh nghiệp-->
          <span
            v-if="props.column.field === 'name'"
          >
            <span class="text-name">{{ props.row.name }}</span>
          </span>

          <!-- Column:Tên đăng nhập-->
          <span v-else-if="props.column.field === 'userName'">
            <span class="text-nowrap">{{ props.row.userName }}</span>
          </span>

          <!-- Column:địa chỉ-->
          <span v-else-if="props.column.field === 'address'">
            <span>{{ props.row.address }}</span>
          </span>

          <!-- Column:email -->
          <span v-else-if="props.column.field === 'email'">
            <span class="text-nowrap">{{ props.row.email }}</span>
          </span>

          <!-- Column:Trạng thái -->
          <span v-else-if="props.column.field === 'emailConfirmed'">
            <b-badge
              pill
              :variant="statusVariant(props.row.emailConfirmed)"
              class="border-status"
            >
              <span>{{ props.row.emailConfirmed === true ? 'Đã kích hoạt' : 'Chưa kích hoạt' }}</span>

            </b-badge>
          </span>
          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'actionFunction'">
            <span
              title="Chỉnh sửa"
              @click="$router.push({name: 'business-edit', params: {id: props.row.id}})"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              v-if="$userData().roleIdentity !== 'EducationDepartment'"
              title="Xóa"
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
            <span>
              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body"
                  />
                </template>
                <!-- xem chi tiet  -->
                <b-dropdown-item>
                  <span
                    v-if="!props.row.emailConfirmed"
                    @click="showModalActive(props.row.userId)"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      class="mr-50"
                    />
                    <span>{{ 'Kích hoạt' }}</span>
                  </span>
                  <span
                    v-if="props.row.emailConfirmed"
                    @click="unActive(props.row.userId)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      class="mr-50"
                    />
                    <span>{{ 'Hủy kích hoạt' }}</span>
                  </span>
                </b-dropdown-item>

                <!-- Cấp lại mật khẩu  -->
                <b-dropdown-item @click="handleOpenModal(props.row.userId)">
                  <feather-icon
                    icon="LockIcon"
                    size="16"
                    class="mr-50"
                  />
                  <span>Cấp mật khẩu</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        class="pt-5"
        :totalItems="totalPages"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :title="''"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!-- active tài khoản -->
      <modal-active-account
        :id="modalActiveAccount"
        @activeAccount="activeAccount"
      />

      <!-- modal cấp lại mật khẩu -->
      <modal-reissue-password
        idModalReissuePassword="modalPassword"
        @handleReissuePassword="handleReissuePassword"
      />

      <sync-business
        :id="idModalSync"
        :rows="dataBusinessSync.pageLists"
        :totalRecord="dataBusinessSync.totalRecord"
        @pageChange="pageChangeListSyncBusiness"
        @hideModalSyncBusiness="hideModalSyncBusiness"
        @syncAllBusiness="syncAllBusiness"
        @syncListBusiness="syncListBusiness"
      />

    </div>
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import { mapActions } from 'vuex'
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BCol,
  BRow,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import ConstanstApi from '@/views/management-statistical/working-age/constants/ConstanstApi'

export default {
  components: {
    BBadge,
    BDropdown,
    BDropdownItem,
    BCol,
    BRow,
    BFormGroup,
    VSelect: () => import('vue-select'),
    ConfirmModal: () => import('@/components/confirm-modal/ConfirmModal.vue'),
    MyPagination: () => import('@/components/pagination/MyPagination.vue'),
    VueGoodTable: () => import('@/components/table/VueGoodTable.vue'),
    ButtonAllHeader: () => import('@/views/common/ButtonAllHeader.vue'),
    ModalActiveAccount: () => import('./tag-list/ModalActiveAccount.vue'),
    ModalReissuePassword: () => import('./components/ModalReissuePassword.vue'),
    SyncBusiness: () => import('./components/ListBusinessSync.vue'),
  },
  directives: {
    Ripple: () => import('vue-ripple-directive'),
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      idModalSync: 'modal-async-business',
      modalActiveAccount: 'confirm-modal-active',
      arrayExcel: ['code', 'name', 'userName', 'password', 'email', 'decisionEstablish', 'unitIssued', 'dateIssued', 'locationIssued', 'dateExpired', 'taxNumber', 'address', 'phoneNumber', 'bussinessType', 'bussinessSector', 'officalProfile', 'bussinessClass', 'userType', 'department'],
      currentPage: 1,
      columns: [
        {
          label: 'Mã doanh nghiệp',
          field: 'code',
        },
        {
          label: 'Tên doanh nghiệp',
          field: 'name',
        },
        {
          label: 'Tên đăng nhập',
          field: 'userName',
        },
        {
          label: 'Địa chỉ',
          field: 'address',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Trạng thái',
          field: 'emailConfirmed',
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          tdClass: 'table-tool',
          thClass: 'table-tool',
        },
      ],
      urlQuery: {
        isActive: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
        provinceId: null,
        districtId: null,
        wardId: null,
      },
      dataStatus: [
        {
          id: true,
          name: 'Đã kích hoạt',
        },
        {
          id: false,
          name: 'Chưa kích hoạt',
        },
      ],
      confirmModalId: 'confirm-modal',
      modalContent: '', // thông báo xóa
      showBtnMultiDelete: false,
      deleteIds: [], // ID xóa
      isActive: false,
      userId: '',
      dataRows: [], // dữ liệu bảng
      totalPages: 10, // tổng số trang
      checkActive: '',
      activeId: null,
      dataTypeBussiness: [],
      dataProvinces: [],
      dataDistricts: [],
      dataWards: [],
      dataBusinessSync: {},
      totalRecordAsync: [],
      urlQueryBusinessSync: {
        pageNumber: 1,
        pageSize: 10,
      },
      syncStatus: true,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        // Chưa kích hoạt
        false: 'light-danger',
        // Kích hoạt
        true: 'light-success',
      }
      return status => statusColor[status]
    },
  },
  async created() {
    this.fecthDataList()
    this.getDataTypeOfBussiness()
    this.getListProvinces()
    if (this.$infoPortal().province === 'Đắk Nông') {
      this.getListBusinessSync()
      this.getStatusSync()
    }
  },
  methods: {
    ...mapActions('business', [
      'dowloadFile',
      'downloadExcelAll',
      'getApiExcel',
    ]),
    async getListProvinces() {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_PROVINCES)
      this.dataProvinces = data
    },
    async getListDistricts(id) {
      if (id) {
        const { data } = await axiosApiInstance.get(`${ConstantsApi.FECTH_DISTRICTS}${id}`)
        this.dataDistricts = data
      }
    },
    async getListWards(id) {
      if (id) {
        const { data } = await axiosApiInstance.get(`${ConstantsApi.FECTH_WARDS}${id}`)
        this.dataWards = data
      }
    },

    async getDataTypeOfBussiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_TYPE)
      this.dataTypeBussiness = data?.data?.pageLists
    },
    //= ==========================================action api========================================
    // Lấy dữ liệu bảng co phân trang
    // eslint-disable-next-line consistent-return
    async fecthDataListST(data) {
      try {
        const resuft = await axiosApiInstance.get(ConstantsApi.FECTH_DATA_LIST, { params: data })
        if (resuft.status === 200) {
          return resuft.data
        }
      } catch (e) {
        return null
      }
    },
    // xóa doanh nghiệp
    // eslint-disable-next-line consistent-return
    async deleteST(data) {
      this.$showAllPageLoading()
      try {
        const data01 = await axiosApiInstance.post(ConstantsApi.DELETEST, data)
        if (data01.status === 200) {
          this.$hideAllPageLoading()
          return {
            oke: true,
            message: { name: 'Xóa Thành Công!', color: 'success' },
          }
        }
      } catch (error) {
        this.$hideAllPageLoading()
        return {
          oke: false,
          message: { name: 'Xóa không thành công!', color: 'danger' },
        }
      }
    },

    // Mở modal
    handleOpenModal(id) {
      this.userId = id
      this.$bvModal.show('modalPassword')
    },

    // Cấp lại mật khẩu cho người dùng
    async handleReissuePassword(val, validate) {
      const payload = {
        ...val,
        userId: this.userId,
      }
      validate.validate().then(async success => {
        if (success) {
          this.$showAllPageLoading()
          await axiosApiInstance.post(ConstantsApi.CHANGE_PASSWORD, payload).then(res => {
            if (res.status === 200) {
              this.$hideAllPageLoading()
              this.$bvToast.toast('Cập nhật thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              val.confirmNewPassword = ''
              val.newPassword = ''
              this.$bvModal.hide('modalPassword')
            }
          }).catch(e => {
            this.$hideAllPageLoading()
            this.$bvToast.toast(e.response.data?.errors.ConfirmNewPassword[0], {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          })
        }
      })
    },

    showModalActive(id) {
      this.activeId = id
      this.$bvModal.show(this.modalActiveAccount)
    },

    // eslint-disable-next-line consistent-return
    async activeAccount(val) {
      const payload = {
        id: this.activeId,
        userTypeId: val,
      }
      try {
        this.$showAllPageLoading()
        const result = await axiosApiInstance.post(ConstantsApi.ACTIVE_BUSINESS, payload)
        if (result.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Kích hoạt thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.$bvModal.hide(this.modalActiveAccount)
        this.fecthDataList()
      } catch (error) {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Kích hoạt không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    // Hủy kích hoạt tài khoản
    // eslint-disable-next-line consistent-return
    async unActive(id) {
      try {
        this.$showAllPageLoading()
        const result = await axiosApiInstance.post(`${ConstantsApi.UN_ACTIVE}${id}`)
        if (result.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Hủy kích hoạt thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.fecthDataList()
      } catch (error) {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Hủy kích hoạt không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // ==========================================action handle ====================================//

    filterProvinces(val) {
      this.dataDistricts = []
      this.getListDistricts(val)
      this.urlQuery.provinceId = val
      this.urlQuery.pageNumber = 1
      this.urlQuery.districtId = null
      this.urlQuery.wardId = null
      this.fecthDataList()
    },
    filterDistricts(val) {
      this.dataWards = []
      this.getListWards(val)
      this.urlQuery.pageNumber = 1
      this.urlQuery.wardId = null
      this.fecthDataList()
    },
    filterWards() {
      this.urlQuery.pageNumber = 1
      this.fecthDataList()
    },

    async fecthDataList() {
      this.$showLoading()
      const data = await this.fecthDataListST(this.urlQuery) // lấy dữ liệu bảng
      this.dataRows = data.data.pageLists
      this.totalPages = data.data.totalRecord
      this.$hideLoading()
    },
    // -----phần tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fecthDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.fecthDataList()
      }
    },

    async filterStatus(val) {
      if (val !== null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.isActive = val
        await this.fecthDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.isActive = null
        await this.fecthDataList()
      }
    },
    async filterTypeBussiness(val) {
      if (val !== null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessTypeId = val
        await this.fecthDataList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessTypeId = null
        await this.fecthDataList()
      }
    },
    // -----phần phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fecthDataList()
    },
    // import file exel
    async importFileExcel(event) {
      if (event) {
        await this.getValidData(event)
      }
    },
    // kiểm tra hợp lệ từ file exel
    async getValidData(dataInput) {
      this.$showAllPageLoading()
      const datafile = dataInput.map(element => ({
        ...element,
        dateIssued: Date.parse(element.dateIssued) ? `${new Date(element.dateIssued).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : element.dateIssued,
        dateExpired: Date.parse(element.dateExpired) ? `${new Date(element.dateExpired).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : element.dateExpired,
      }))
      const model = {
        listExcel: datafile,
        isValidate: true,
      }
      const res = await this.getApiExcel(model)
      if (res.oke) {
        this.$router.push({ name: 'business-list-add' })
      } else {
        this.$bvToast.toast('Nhập file không thành công!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    // ************ chức năng xóa ****************//
    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = [id]
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },
    // chọn hàng table
    selectRowTable(val) {
      this.deleteIds = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteIds.length > 0
    },
    // click xóa nhiều người dùng
    deleteItems() {
      this.modalContent = this.$t('Bạn có muốn xóa mục đã chọn không?')
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      const res = await this.deleteST(this.deleteIds)
      this.fecthDataList()
      this.$hideAllPageLoading()
      this.$bvToast.toast(res.message.name, {
        title: 'Thông báo',
        variant: res.message.color,
        toaster: this.$toastPosition,
        solid: true,
      })
    },
    // =========================hàm mới tạo ====================================//

    // show modal đồng bộ doanh nghiệp
    onSyncBusiness() {
      this.$bvModal.show(this.idModalSync)
    },

    async getListBusinessSync() {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_BUSINESS_SYNC, { params: this.urlQueryBusinessSync })
      this.dataBusinessSync = data?.data
    },

    pageChangeListSyncBusiness(val) {
      this.urlQueryBusinessSync = {
        ...val,
      }
      this.getListBusinessSync()
    },

    hideModalSyncBusiness() {
      this.urlQueryBusinessSync = {
        pageNumber: 1,
        pageSize: 10,
      }
      this.getListBusinessSync()
    },

    getStatusSync() {
      axiosApiInstance.get(ConstantsApi.STATUS_SYNC).then(res => {
        this.syncStatus = res?.data
      })
    },
    syncAllBusiness() {
      axiosApiInstance.post(ConstantsApi.SYNC_ALL_BUSINESS).then(() => {
        this.$bvToast.toast('Dữ liệu đang được đồng bộ...', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.syncStatus = true
        this.$bvModal.hide(this.idModalSync)
      }).catch(() => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
      this.getListBusinessSync()
    },

    syncListBusiness(list) {
      this.$showAllPageLoading()
      axiosApiInstance.post(ConstantsApi.SYNC_LIST_BUSINESS, list).then(() => {
        this.dataBusinessSync = {}
        this.getListBusinessSync()
        this.$hideAllPageLoading()
        this.$bvToast.toast('Đồng bộ thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      }).catch(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }
  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
  .search-input {
    width: 400px;
  }
}
.table-tool{
width: 150px;
text-align: center;
}
</style>
